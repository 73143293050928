module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Luigi Colella Blog","short_name":"Luigi Colella","start_url":"/","background_color":"#0C2744","theme_color":"#0C2744","display":"standalone","icon":"src/images/icon.png","legacy":true,"cache_busting_mode":"query","include_favicon":true,"theme_color_in_head":true,"cacheDigest":"e7fa08d2bd3ee40bd56965b15867f63f"},
    },{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"extensions":[".mdx",".md"],"gatsbyRemarkPlugins":["gatsby-remark-unwrap-images",{"resolve":"gatsby-remark-images","options":{"maxWidth":1000,"quality":80,"showCaptions":true,"linkImagesToOriginal":false}},"gatsby-remark-prismjs","gatsby-remark-copy-linked-files","gatsby-remark-autolink-headers"]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
