import pic01 from "../../../../content/posts/2022-08-09-Seeb-Defender/02 greenhouse-watering.png";
import mov01 from "../../../../content/posts/2022-08-09-Seeb-Defender/greenhouse growth.mp4";
import pic02 from "../../../../content/posts/2022-08-09-Seeb-Defender/04 firing.png";
import pic03 from "../../../../content/posts/2022-08-09-Seeb-Defender/07 research-tree-zoomed-out.png";
import pic04 from "../../../../content/posts/2022-08-09-Seeb-Defender/09 bombering.png";
import pic05 from "../../../../content/posts/2022-08-09-Seeb-Defender/10 bombering.png";
import pic06 from "../../../../content/posts/2022-08-09-Seeb-Defender/15 firing.png";
import pic07 from "../../../../content/posts/2022-08-09-Seeb-Defender/16 firing.png";
import * as React from 'react';
export default {
  pic01,
  mov01,
  pic02,
  pic03,
  pic04,
  pic05,
  pic06,
  pic07,
  React
};