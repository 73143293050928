import treeVid from "../../../../content/posts/2022-08-09-L system language/examples/tree-video.mp4";
import lightSeeker from "../../../../content/posts/2022-08-09-L system language/examples/light-seeker.mp4";
import fieldFlower from "../../../../content/posts/2022-08-09-L system language/examples/field-flower.mp4";
import fractal from "../../../../content/posts/2022-08-09-L system language/examples/colorful-fractal.mp4";
import menu from "../../../../content/posts/2022-08-09-L system language/examples/lettered-menu.mp4";
import turtle from "../../../../content/posts/2022-08-09-L system language/turtle-animation.mp4";
import turtleStar from "../../../../content/posts/2022-08-09-L system language/turtle-animation-star-instructions.mp4";
import * as React from 'react';
export default {
  treeVid,
  lightSeeker,
  fieldFlower,
  fractal,
  menu,
  turtle,
  turtleStar,
  React
};