import copiedCylinders from "../../../../content/posts/2022-08-28-Stem Generation/basic-mesh-copy.mp4";
import cylindersScronked from "../../../../content/posts/2022-08-28-Stem Generation/scrunkled-cylinder.mp4";
import descronkled from "../../../../content/posts/2022-08-28-Stem Generation/deskrunkled.mp4";
import circleRingShifted from "../../../../content/posts/2022-08-28-Stem Generation/circle-ring-shifted.mp4";
import basicMapBrokenWrap from "../../../../content/posts/2022-08-28-Stem Generation/basic-texture uv-wrap-broken.mp4";
import basicMapFixedWrap from "../../../../content/posts/2022-08-28-Stem Generation/basic-texture uv-wrap-fixed.mp4";
import textureBlendInitial from "../../../../content/posts/2022-08-28-Stem Generation/texture-blended-cylinders.mp4";
import * as React from 'react';
export default {
  copiedCylinders,
  cylindersScronked,
  descronkled,
  circleRingShifted,
  basicMapBrokenWrap,
  basicMapFixedWrap,
  textureBlendInitial,
  React
};